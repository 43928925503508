<template>
  <div class="news">
    <div class="news__left w-full">
      <div class="category pc" v-if="category">
        <img src="../assets/img/logo/logo-category.svg" alt="" />
        <a class="before" href="#">Trang chủ</a>
        <span class="tag-mid">/</span>
        <a class="before" href="#">Tin Tức & Sự Kiện</a>
        <span class="tag-mid" v-if="category[locale]?.name">/</span>
        <a class="after" href="#" v-if="category[locale]?.name">{{
          category[locale]?.name
        }}</a>
      </div>
      <div class="news-main">
        <div class="news-main__title" data-aos="fade-up" v-if="category">
          <h5>{{ category[locale]?.name }}</h5>
          <h1>{{ category[locale]?.content }}</h1>
        </div>
        <div class="news-main__content" data-aos="fade-up">
          <div class="post-left" v-if="newsFist">
            <div class="item">
              <router-link
                :to="
                  '/news/' +
                  category?.slug +
                  '/' +
                  newsFist.slug +
                  '?id=' +
                  newsFist.id
                "
                ><div class="item__bg">
                  <img :src="newsFist.image" alt="" /></div
              ></router-link>

              <h5 class="item__category">{{ category[locale]?.name }}</h5>
              <router-link
                :to="
                  '/news/' +
                  category.slug +
                  '/' +
                  newsFist.slug +
                  '?id=' +
                  newsFist.id
                "
                ><h1 class="item__title">
                  {{ newsFist[locale]?.title }}
                </h1></router-link
              >
              <h1 class="item__time">{{ getDay(newsFist?.created_at) }}</h1>
              <p class="item__des">
                {{ newsFist[locale]?.description }}
              </p>
              <div class="item__view">
                <router-link
                  :to="
                    '/news/' +
                    category.slug +
                    '/' +
                    newsFist.slug +
                    '?id=' +
                    newsFist.id
                  "
                >
                  <img
                    src="../assets/img/icons/arrow-right-orange.svg"
                    alt=""
                  />
                  <span>XEM TIẾP</span>
                </router-link>
              </div>
            </div>
          </div>
          <div class="post-right" v-if="newList && newList.length > 0">
            <div class="item" v-for="(item, index) in newList" :key="index">
              <router-link
                :to="
                  '/news/' + category.slug + '/' + item.slug + '?id=' + item.id
                "
              >
                <div class="item__bg">
                  <img :src="item.image" alt="" />
                </div>
                <h5 class="item__category">{{ category[locale]?.name }}</h5>
                <h1 class="item__title">
                  {{ item[locale].title }}
                </h1>
                <p class="item__time">{{ getDay(item.created_at) }}</p>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="new-other">
        <div
          class="item"
          data-aos="fade-up"
          v-for="(item, index) in newOther"
          :key="index"
        >
          <router-link
            class="item-bg"
            :to="'/news/' + category.slug + '/' + item.slug + '?id=' + item.id"
            ><div class="item__bg">
              <img :src="item.image" alt="" /></div
          ></router-link>

          <div class="item-content">
            <h5 class="item__category">{{ category[locale]?.name }}</h5>
            <router-link
              class="item-bg"
              :to="
                '/news/' + category.slug + '/' + item.slug + '?id=' + item.id
              "
            >
              <h1 class="item__title">
                {{ item[locale]?.title }}
              </h1></router-link
            >
            <h1 class="item__time">{{ getDay(item.created_at) }}</h1>
            <p class="item__des">
              {{ item[locale]?.description }}
            </p>
            <div class="item__view">
              <router-link
                class="item-bg"
                :to="
                  '/news/' + category.slug + '/' + item.slug + '?id=' + item.id
                "
              >
                <img src="../assets/img/icons/arrow-right-orange.svg" alt="" />
                <span>XEM TIẾP</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NewsService from "@/services/NewsService";
import { mapGetters } from "vuex";
export default {
  components: {},
  data() {
    return {
      dataNews: "",
      newsFist: "",
      newList: [],
      newOther: [],
      category: "",
    };
  },
  mounted() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.getDataSlug();
    this.getCategory();
  },
  computed: {
    ...mapGetters(["locale"]),
  },
  watch: {
    "$route.params"() {
      this.getDataSlug();
      this.getCategory();
    },
  },
  methods: {
    // ...mapActions(["setMenu"]),
    getDay(day) {
      if (day) {
        const arr = day.split("-");
        return arr[0] + " Tháng " + arr[1] + " năm " + arr[2];
      }
      return '';
    },
    async getDataSlug() {
      this.newsFist = [];
      this.newList = [];
      this.newOther = [];
      await NewsService.getNewsSlug(this.$route.params.slug)
        .then((resp) => {
          this.dataNews = resp.data.data;
          if (this.dataNews?.length > 0) {
            this.newsFist = this.dataNews[0];
          }
          if (this.dataNews?.length > 1) {
            this.newList = this.dataNews.slice(1, 3);
          }
          if (this.dataNews?.length > 3) {
            this.newOther = this.dataNews.slice(3, this.dataNews.length);
          }
        })
        .catch(() => {});
    },
    async getCategory() {
      await NewsService.getCategorySlug(this.$route.params.slug)
        .then((resp) => {
          this.category = resp.data.data;
        })
        .catch(() => {});
    },
  },
};
</script>
